"use client"

import { Search, SparklesIcon } from "lucide-react"
import { Button } from "@/components/ui/button"
import { useRouter } from "next/navigation"
import { motion, AnimatePresence } from "framer-motion"
import { useEffect, useRef } from "react"

const EXAMPLE_SEARCHES = [
    { text: "AI in education", query: "artificial intelligence in education" },
    { text: "Climate Change", query: "climate change and the economical impact on the world" },
    { text: "Business Innovation in Europe", query: "business innovation startup examples in europe" },
    { text: "Health & Medicine", query: "health medical research breakthroughs" }
]

export default function SearchHeader() {
    const router = useRouter()
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        // Small delay to ensure the animation has started
        const timer = setTimeout(() => {
            inputRef.current?.focus()
        }, 400)

        return () => clearTimeout(timer)
    }, [])

    const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const formData = new FormData(e.currentTarget)
        const query = formData.get("q")
        if (query) {
            router.push(`/search?q=${encodeURIComponent(query.toString())}`)
        }
    }

    const handleExampleClick = (query: string) => {
        router.push(`/search?q=${encodeURIComponent(query)}`)
    }

    return (
        <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{
                height: "auto",
                opacity: 1,
                transition: {
                    height: { duration: 0.4, ease: [0.33, 1, 0.68, 1] },
                    opacity: { duration: 0.3, delay: 0.1 }
                }
            }}
            className="relative w-full bg-white border-b border-tce-700/50 overflow-hidden"
        >
            <div className="absolute inset-0 bg-[linear-gradient(90deg,rgba(255,255,255,0.1)_1px,transparent_1px),linear-gradient(0deg,rgba(255,255,255,0.1)_1px,transparent_1px)] bg-[length:3rem_3rem]" />

            <motion.div
                initial={{ y: -20 }}
                animate={{
                    y: 0,
                    transition: {
                        duration: 0.4,
                        ease: [0.175, 0.885, 0.32, 1.275] // Bounce effect
                    }
                }}
                className="relative max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8"
            >
                <div className="space-y-3">
                    <form onSubmit={handleSearch} className="flex gap-2">
                        <div className="relative flex-1">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <Search className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input
                                ref={inputRef}
                                type="text"
                                name="q"
                                className="block w-full rounded-md border-3 border-orange-500 bg-white py-2.5 pl-10 pr-3 text-black placeholder:text-gray-400 outline-none focus:outline-none focus:ring-0 focus:border-orange-500 focus-visible:outline-none focus-visible:ring-0 focus-visible:border-orange-500 sm:text-sm sm:leading-6 h-12 shadow-[0_0_0_0_rgba(249,115,22,0)] focus:shadow-[0_0_0_4px_rgba(249,115,22,0.15)] transition-shadow"
                                placeholder="Search through hundreds of thousands of premium articles..."
                            />
                        </div>
                        <Button type="submit" variant="orange" size="lg" className="hidden sm:flex h-12 px-8">
                            <SparklesIcon className="w-4 h-4 mr-2" />
                            Search
                        </Button>
                    </form>

                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{
                            opacity: 1,
                            y: 0,
                            transition: {
                                delay: 0.2,
                                duration: 0.3
                            }
                        }}
                        className="flex flex-wrap gap-2 items-center text-sm text-gray-400"
                    >
                        {/* <span className="text-gray-500">Examples:</span> */}
                        {EXAMPLE_SEARCHES.map((search, i) => (
                            <Button
                                key={i}
                                variant="ghost"
                                size="sm"
                                className="hover:bg-stone-200 text-stone-900 bg-stone-50 border-2 border-stone-100"
                                onClick={() => handleExampleClick(search.query)}
                            >
                                {search.text}...
                            </Button>
                        ))}
                    </motion.div>
                </div>
            </motion.div>
        </motion.div>
    )
}
