"use client"

import { useEffect, useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import NumberFormatter from "@/components/common/number-formatter"
import { cn } from "@/lib/utils"

export function DynamicContentCounter() {
    const START_COUNT = 1_593_510
    const START_DATE = "2024-12-08T00:00:00Z"
    const INCREMENT_INTERVAL_SECONDS = 32

    const [count, setCount] = useState(START_COUNT)
    const [isIncrementing, setIsIncrementing] = useState(false)

    useEffect(() => {
        function calculateCurrentCount() {
            const start = new Date(START_DATE).getTime()
            const now = Date.now()
            const secondsElapsed = (now - start) / 1000
            const additionalItems = Math.floor(secondsElapsed / INCREMENT_INTERVAL_SECONDS)
            return START_COUNT + additionalItems
        }

        // Initial calculation
        const initialCount = calculateCurrentCount()
        setCount(initialCount)

        // Update every 20 seconds
        const interval = setInterval(() => {
            const newCount = calculateCurrentCount()
            if (newCount !== count) {
                setIsIncrementing(true)
                setCount(newCount)
                // Reset the animation state after 1 second
                setTimeout(() => setIsIncrementing(false), 1000)
            }
        }, 1000) // Check more frequently for smoother updates

        return () => clearInterval(interval)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // Remove count dependency

    return (
        <AnimatePresence>
            <motion.span
                className={cn(
                    "relative transition-all duration-1000 ease-out",
                    isIncrementing && "bg-primary/10 dark:bg-primary/20 rounded-lg"
                )}
                animate={{
                    scale: isIncrementing ? 1.05 : 1
                }}
                transition={{ duration: 0.5 }}
            >
                <motion.span
                    key={count}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                    className="p-0"
                >
                    <NumberFormatter value={count} />
                </motion.span>
            </motion.span>
        </AnimatePresence>
    )
}
