"use client"

import { useEffect } from "react"
import { useRouter } from "next/navigation"

/*
 * This function is to redirect urls coming from the old dashboard to the new one.
 */

export default function HashRedirect() {
    const router = useRouter()

    useEffect(() => {
        const hash = window.location.hash

        if (hash.startsWith("#/dashboard")) {
            // Parse everything after #/dashboard as a new URL to get the search params
            const hashUrl = new URL("http://dummy.com" + hash.substring(1))
            const contentItemId = hashUrl.searchParams.get("content-item-id")
            const newUrl = new URL("/dashboard", window.location.origin)
            if (contentItemId) {
                // now the url should be /dashboard/content/{url} to the new url:
                newUrl.pathname = `/dashboard/content/${contentItemId}`
            }
            console.log("Redirecting to:", newUrl.toString())
            router.replace(newUrl.toString())
        }
    }, [router])

    return null
}
