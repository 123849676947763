"use client"

import { useState, useEffect } from "react"
import Image from "next/image"
import { Calendar } from "lucide-react"
import { Badge } from "@/components/ui/badge"
import { cn } from "@/lib/utils"
import DateFormatter from "@/components/common/date-formatter"
import type { BlogPost } from "@/lib/blogs/blog-post.schema"

interface BlogPostOverlayProps {
    post: BlogPost | null | undefined
}

export function BlogPostOverlay({ post }: BlogPostOverlayProps) {
    const [isOpen, setIsOpen] = useState(false)
    const [hasBeenShown, setHasBeenShown] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsOpen(true)
            setHasBeenShown(true)
        }, 1000)

        return () => clearTimeout(timer)
    }, [])

    if (!post) return null

    return (
        <div className="absolute top-3 right-3 lg:top-6 lg:right-8 hidden md:block">
            <a
                href={`/blog/${post.slug}`}
                className={cn(
                    "block w-64 bg-stone-200 backdrop-blur-sm",
                    "rounded-lg overflow-hidden border border-white/10 shadow-xl",
                    "transform transition-all duration-500",
                    "hover:scale-105 shadow-2xl",
                    isOpen ? "translate-y-0 opacity-100" : "-translate-y-full opacity-0"
                )}
            >
                <div className="relative h-20 w-full">
                    <Image src={post.coverImage} alt="" fill className="object-cover" sizes="256px" />
                    <div className="absolute top-2 left-2">
                        <Badge variant="default" className="text-xs shadow-xl bg-primary/80 backdrop-blur-sm">
                            Latest from our blog
                        </Badge>
                    </div>
                </div>
                <div className="p-2">
                    <h3 className="text-sm font-semibold text-stone-900 mb-2 line-clamp-4">{post.title}</h3>
                    <div className="flex items-center justify-end text-xs text-stone-700">
                        <Calendar className="w-3 h-3 mr-1" />
                        <DateFormatter date={post.date} />
                    </div>
                </div>
            </a>
        </div>
    )
}
