"use client"

import { useEffect, useState, type JSX } from "react";

type Props = {
    value: number
    prefix?: string
    className?: string
}

export function useNumberFormatter(value: number): string {
    const [formattedValue, setFormattedValue] = useState("")
    useEffect(() => setFormattedValue(value.toLocaleString()), [value])
    return formattedValue
}

export default function NumberFormatter({ value, prefix, className }: Props): JSX.Element {
    const prefixString = prefix ? `${prefix}` : " "
    return (
        <span className={className}>
            {prefixString}
            {useNumberFormatter(value)}
        </span>
    )
}
